<template>
  <div class="flex w-full items-center justify-center">
    <div class="rounded shadow bg-white p-6 w-full md:max-w-2xl">
      <div v-if="status == 'unsubscribed'">
        <div class="text-gray-800 font-medium text-2xl">
          Uitschrijving gelukt
        </div>
        <div class="mt-4">
          U bent uitgeschreven voor e-mailings van <span class="font-medium">{{ info.company_name }}</span> met e-mailadres <span class="font-medium">{{ info.email }}</span>. Mocht u zich bedenken, kunt u zich altijd weer inschrijven.
        </div>
        <!-- <button @click.prevent="subscribe" class="mt-4 bg-blue-500 text-white rounded shadow px-4 py-2 font-medium hover:bg-blue-600 transition duration-200">Inschrijven</button> -->
      </div>
      
      <div v-if="status == 'failed'">
        <div class="text-gray-800 font-medium text-2xl">
          Uitschrijving mislukt
        </div>
        <div class="mt-4">
          Er is iets mis gegaan het afmeldproces. Probeer het later opnieuw of stuur een e-mail naar <a href="mailto:support@mymarketingmanager.nl?subject=Uitschrijven e-mailadres&body=Uit te schrijven e-mailadres:%0D%0ANaam Autobedrijf:" target="_blank" class="text-blue-600 underline">support@mymarketingmanager.nl</a>. Vernoem hierin het e-mailadres dat u wilt uitschrijven en de naam van het autobedrijf. Excuses voor het ongemak.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      info: {},
      status: 'failed',
      key: null
    }
  },
  methods: {
    async unsubscribe() {
      try {
        let response = await this.axios.post('https://marketingapi.nl/carteam/privacy/uitschrijven', {
          Key: this.key,
          Email: '0'
        })
        this.info = response.data
        this.status = 'unsubscribed'
      } catch (e) {

      }
    },
    // async subscribe() {
    //   try {
    //     let response = await this.axios.post('https://marketingapi.nl/users/uitschrijven', {
    //       Key: this.key,
    //       Email: '1'
    //     })
    //     this.info = response.data
    //     this.status = 'subscribed'
    //   } catch (e) {
        
    //   }
    // }
  },
  created() {
    this.key = this.$route.params.key
    this.unsubscribe()
  }
  
}
</script>
